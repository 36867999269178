<template>
  <v-app id="login" class="secondary">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md3 lg3>
            <v-card class="elevation-4 pa-3">
              <v-card-text>
                <h1 class="flex my-4 primary--text text-center rounded-lg">
                  TSMIS
                </h1>
                <div class="layout column align-center">
                  <img
                    src="../../../public/nembo_taifa.png"
                    alt="e-Assessment system"
                    width="100"
                    height="100"
                  />
                  <h3
                    class="flex my-4 primary--text text-center text--disabled "
                  >
                    {{ title }}
                  </h3>
                </div>

                <v-form v-if="!isOpen">
                  <v-text-field
                    append-icon="mdi-account"
                    name="login"
                    label="Login"
                    type="text"
                    v-model="email"
                    :error="error"
                    :rules="[rules.required, rules.emailRules]"
                    clearable
                  />
                  <el-tooltip
                    v-model="capsTooltip"
                    content="Caps lock is On"
                    placement="right"
                    manual
                  >
                    <v-text-field
                      :type="hidePassword ? 'password' : 'text'"
                      :append-icon="hidePassword ? 'mdi-eye' : 'mdi-eye-off'"
                      name="password"
                      label="Password"
                      id="password"
                      :rules="[rules.required]"
                      v-model="password"
                      :error="error"
                      clearable
                      @click:append="hidePassword = !hidePassword"
                      @keyup.native="checkCapsLock"
                      @blur="capsTooltip = false"
                      @keyup.enter.native="login"
                    />
                  </el-tooltip>
                </v-form>
              </v-card-text>
              <v-card-actions v-if="!isOpen">
                <v-spacer></v-spacer>
                <v-btn
                  block
                  color="primary"
                  tile
                  @click="login"
                  :loading="loading"
                >
                  <v-icon left> mdi-login-variant</v-icon>
                  Login
                </v-btn>
              </v-card-actions>

              <br />

              <hr />
              <p class="primary--text" style="cursor:pointer" @click="open">
                Forgot Password?
              </p>
              <v-card-text v-if="isOpen">
                <v-row>
                  <v-text-field
                    append-icon="mdi-account"
                    name="recoverEmail"
                    label="Enter Email"
                    type="text"
                    v-model="email"
                    :error="error"
                    :rules="[rules.required, rules.emailRules]"
                    clearable
                  />
                </v-row>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <DialogButtons
                    :close="close"
                    :save="restore"
                    :loading="loading"
                    :valid="valid"
                    :title="'send'"
                    :save-icon="'mdi-send'"
                  />
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
      <v-snackbar v-model="showResult" :timeout="2000" top>
        {{ result }}
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import { loginApis } from "./services";

export default {
  data() {
    return {
      valid: true,
      loading: false,
      capsTooltip: false,
      email: null,
      password: null,
      hidePassword: true,
      error: false,
      showResult: false,
      result: "",
      isOpen: false,

      rules: {
        required: (value) => !!value || "Required.",
        emailRules: (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      },
    };
  },

  methods: {
    restore() {
      this.loading = true;
      loginApis
        .resetPassword({ email: this.email })
        .then((response) => {
          this.isOpen = false;
          this.result = response.data.message;
          this.loading = false;
          this.showResult = true;
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
          // this.result = err?.response?.data?.message;
          this.result = err?.response?.data?.message;

          this.showResult = true;
        });
    },

    close() {
      this.isOpen = false;
    },

    open() {
      this.isOpen = true;
    },

    login() {
      if (!this.email || !this.password) {
        this.result = "Email and Password can't be null.";
        this.showResult = true;

        return;
      }
      this.loading = true;
      loginApis
        .authenticate({ email: this.email, password: this.password })
        .then((response) => {
          if (response?.data?.data?.token && response.status == 200) {
            this.loading = false;
            const data = response.data.data;
            data.user.token = data.token;
            data.user.menus = data.menus;
            data.user.academicYear = data.academicYear;

            //  data.user.permissions = data.permissions;
            // data.user.children = data.children;
            data.user.userLocation = data.currentLocation;
            const user = data.user;
            user.photo = user.photo
              ? "data:image/png;base64," + user.photo
              : null;
            this.$store.commit("AUTHENTICATE", user);
            this.$mixins.setItem("eExam-user", user);
            this.$store.dispatch("getMetadataCategories");
            this.$router.push("/");
          }
        })
        .catch((err) => {
          if (err) {
            this.loading = false;
            // console.log("mmm", err);
            this.result = err?.response?.data?.message;
            //this.result = err.response
            this.showResult = true;
          }

          this.$store.commit("loginError", err);
        });
    },
    checkCapsLock(e) {
      const { key } = e;
      this.capsTooltip = key && key.length === 1 && key >= "A" && key <= "Z";
    },
  },
  computed: {
    loginError() {
      return this.$store.getters.loginError;
    },
    user() {
      return this.$store.getters.getCurrentUser;
    },

    title() {
      return process.env.VUE_APP_NAME;
    },
  },
  created() {
    let theme = { name: "Teal", code: "#009688" };

    this.$mixins.setItem("theme", theme);
  },
  mounted() {
    //console.log("is-user", this.$store.getters.getCurrentUser);
  },
};
</script>

<style scoped lang="css">
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  main: "";
  z-index: 0;
}
</style>
