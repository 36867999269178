import axios from "axios";
const ENDPOINT = '/api/v1/login'


export const loginApis = {

  async authenticate(payload) {

    return axios.post(ENDPOINT, payload);
  },

  async resetPassword(payload) {

    return axios.post('/api/v1/public-reset-password', payload);
  }


};
